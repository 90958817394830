/* global Vuex */

const Header = {
  name: 'Header',
  template: `
  <header class="header">
    <a href="/">
      <img src="/media/brand/skodaLogo.svg" alt="Logo">
    </a>
    <a role="button" class="header__back" @click="handleClick" v-if="$store.getters.getCurrentIndex && $store.getters.getCurrentIndex !== 4">
      <i class="icon-back"></i>
    </a>
    <p v-if="$store.getters.getSelectedModel && $store.getters.getCurrentIndex && $store.getters.getCurrentIndex !== 4" class="model-title">{{ $store.getters.getSelectedModel.name }}</p>
</header>
  `,
  emits: ['update'],
  setup (props, { emit }) {
    const store = Vuex.useStore()

    const handleClick = () => {
      store.commit('goBack')
    }

    return { handleClick }
  },
}

export default Header
