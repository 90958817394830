const TextModule = {
  name: 'TextModule',
  template: `
  <section class="text-module">
  <div class="container">
    <div class="grid">
      <div class="col-xl-8 col-12">
        <h1 class="h1">{{ step1.title }}</h1>
      </div>
      <div class="col-xl-4 col-12 text-xl">
        <h3>{{ step1.description }}</h3>
      </div>
    </div>
  </div>
</section>
  `,
  props: ['step1'],
}

export default TextModule
