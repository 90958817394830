/* global Vuex */

const CardModel = {
  name: 'CardModel',
  template: `
  <article class="card card--vehicle" @click="handleClick">
  <div class="aspect-16-9">
    <img :src="host + data.image" :alt="data.model_name">
  </div>
  <div>
    <h5 class="model-name">{{ data.model_name }} {{ data.engine }}</h5>
    <p class="model-desc">{{ data.fuel }} / {{ data.hp }} KM / {{ data.kw }} kW</p>
    <p class="model-desc">{{ transmissionName(data.shift) }} menjalnik</p>
    <p class="model-desc"><i class="icon-location"></i>{{ data.owner_name }}, {{ data.owner_city }}</p>
  </div>
</article>
  `,
  props: ['data', 'disableClick', 'transmissions'],
  setup (props, { emit }) {
    const store = Vuex.useStore()
    const host = window.API_URL.replace(/\/api\/.+$/, '')

    const handleClick = () => {
      if (!props.disableClick) {
        store.commit('setSelectedVehicle', props.data)
        store.commit('goNext')
      }
    }

    const transmissionName = (shift) => {
      if (!props.transmissions) return ''
      return props.transmissions.filter(transmissionType => transmissionType.key === shift)[0]?.name || ''
    }

    return { handleClick, transmissionName, host }
  },
}

export default CardModel
