/* global Vue,Vuex, dropdown */

import CardModel from '../components/card-model'
import LocationContact from '../components/location-contact'
import moment from 'moment'

const Step3 = {
  name: 'step3',
  components: {
    CardModel,
    LocationContact,
    dropdown,
  },
  template: `
  <section class="step3" v-if="$store.getters.getSelectedModel">
    <div class="grid container card-wrapper">
      <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12">
        <h2>{{ step3.title }} <span>1/2</span></h2>
      </div>

      <div class="col-xl-3 offset-xl-2 col-lg-4 offset-lg-1 col-md-4 offset-md-1 col-sm-5 col-12 order-2 order-sm-1">
        <CardModel :disableClick="true" v-if="$store.getters.getSelectedVehicle" :data="$store.getters.getSelectedVehicle" :transmissions="transmissionList"/>
      </div>

      <div class="col-xl-3 offset-xl-7 col-lg-5 offset-lg-6 col-md-4 offset-md-7 col-sm-5 offset-sm-7 col-12 order-sm-1">
        <div class="form-input" v-if="showCalendar">
          <p>{{ step3.testday }}</p>
          <el-date-picker
            v-model="calendarValue.value"
            type="date"
            format="DD.MM.YYYY"
            :disabledDate="isDateDisabled"
            placeholder="Izberite datum"
            @change="selectedDate"
            ></el-date-picker>
        </div>

        <div class="form-input">
          <p>{{ step3.testtime }}</p>
          <dropdown
            v-model="modelTime"
            :disabled="!calendarValue.value"
            :options="timesAvailable"
            option-label="name"
            option-value="id"
            placeholder="Izberite možen termin"
            @change="selectedTime" />
        </div>

        <div class="form-input step3__box" v-if="representerDetails">
          <LocationContact :representerDetails="representerDetails"></LocationContact>
        </div>
      </div>

      <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-12 flex order-sm-1">
          <a role="button" @click="$store.commit('goBack')" class="button">{{ step3.backbutton }}</a>
          <a role="button" @click="handleClick" :disabled="!isValid" class="button button--primary">{{ step3.nextbutton }}</a>
      </div>

      <div class="hide-desktop col-12 order-3">
        <div class="form-input step3__box" v-if="representerDetails">
          <LocationContact :representerDetails="representerDetails"></LocationContact>
        </div>
      </div>
    </div>
  </section>
  `,
  setup () {
    const step3 = JSON.parse(document.querySelector('#app').dataset.step3)
    const calendarValue = Vue.ref({ value: '' })
    const modelTime = Vue.ref(null)
    let allPossibleDates = []
    const representerDetails = Vue.ref(null)
    const modelTimesList = Vue.ref(null)
    const timesAvailable = Vue.ref(null)
    const store = Vuex.useStore()
    const showCalendar = Vue.computed(() => {
      return modelTimesList.value
    })
    const transmissionList = Vue.ref(store.getters.getTransmissionList || [])

    const isValid = Vue.computed(() => {
      return modelTime.value
    })

    function unite () {
      return [].concat.apply([], arguments).filter(function (elem, index, self) {
        return self.indexOf(elem) === index
      })
    }

    function handleClick () {
      if (!isValid.value) return
      store.commit('goNext')
    }

    function isDateDisabled (date) {
      return allPossibleDates.indexOf(moment(date).format('DD.MM.YYYY')) === -1
    }

    // select calendar date - compile possible hours dropdown
    function selectedDate () {
      const selectedDay = moment(calendarValue.value.value).format('DD.MM.YYYY')
      modelTime.value = null

      if (selectedDay !== store.getters.getSelectedDate) {
        timesAvailable.value = modelTimesList.value.filter((item) => {
          return item.days.indexOf(selectedDay) !== -1
        }).map((x) => {
          return { name: x.hours, id: x.id }
        }).sort((a, b) => a.name.localeCompare(b.name))
        store.commit('setSelectedDate', selectedDay)
      }
    }

    // select hour from drodown and save term_id
    function selectedTime (value) {
      if (value.value !== store.getters.getSelectedTermId) {
        modelTime.value = value.value
        store.commit('setSelectedTermId', value.value)
      }
    }

    function setValuesToModels (res) {
      representerDetails.value = res.representer_list[0]
      modelTimesList.value = res.terms
      transmissionList.value = store.getters.getTransmissionList

      const availableDays = modelTimesList.value.map((item) => {
        return item.days
      })

      allPossibleDates = unite([].concat.apply([], availableDays))
    }

    Vue.watchEffect(() => {
      if (store.getters.getCurrentIndex === 2) {
        if (!store.getters.getStep2 || store.getters.getPrevIndex < 2) {
          modelTime.value = null
          modelTimesList.value = null
          timesAvailable.value = null
          allPossibleDates = []
          fetchData()
        } else setValuesToModels(store.getters.getStep3)
      }
    })

    function fetchData () {
      store.commit('setLoading')
      fetch(`${window.API_URL}vehicle-terms`, {
        method: 'post',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ vehicleId: store.getters.getSelectedVehicle.id }),
      }).then(res => res.json())
        .then((res) => {
          store.commit('setLoaded')
          store.commit('setStep3', res.records)
          setValuesToModels(res.records)
        })
    }

    return {
      step3,
      calendarValue,
      showCalendar,
      modelTime,
      isValid,
      modelTimesList,
      representerDetails,
      timesAvailable,
      allPossibleDates,
      transmissionList,
      selectedDate,
      handleClick,
      selectedTime,
      isDateDisabled,
    }
  },
}

export default Step3
