/* global dropdown, Vue */

const Filters = {
  name: 'Filters',
  components: {
    'p-dropdown': dropdown,
  },
  template: `
  <section class="filters">
    <p>{{ title }}</p>
    <div>
      <div v-if="switchOn" class="switch-wrapper">
        <input id="on" name="state-d" value="0" type="radio" v-model="modelSwitch" @change="handleSwitch">
        <input id="na" name="state-d" value="all" type="radio" checked="checked" v-model="modelSwitch" @change="handleSwitch">
        <input id="off" name="state-d" value="1" type="radio" v-model="modelSwitch" @change="handleSwitch">
        <p class="switch-text">{{ switchOptions[0].name }}</p>
        <div class="switch">
          <label for="on"></label>
          <label for="na"></label>
          <label for="off"></label>
        </div>
        <p class="switch-text switch-text--right">{{ switchOptions[1].name }}</p>
      </div>
      <p-dropdown v-if="!hideDropDown" v-model="modelDropDown" :options="options" @change="handleDropDown" :placeholder="placeholder" />
    </div>
  </section>
  `,
  props: ['model', 'title', 'options', 'placeholder', 'switchOn', 'hideDropDown', 'switchOptions'],
  emits: ['updateSwitch', 'updateDropdown'],
  setup (props, { emit }) {
    const modelSwitch = Vue.ref(null)
    const modelDropDown = Vue.ref(null)
    const handleSwitch = () => {
      emit('updateSwitch', modelSwitch.value)
    }

    const handleDropDown = () => {
      emit('updateDropdown', modelDropDown.value)
    }

    return { handleDropDown, handleSwitch, modelSwitch, modelDropDown }
  },
}

export default Filters
