/* global Vue,Vuex */

import CardModel from '../components/card-model'
import LocationContact from '../components/location-contact'

const Step5 = {
  name: 'step5',
  components: {
    CardModel,
    LocationContact,
  },
  template: `
  <section class="step5 step4" v-if="$store.getters.getSelectedModel">
    <div class="grid container card-wrapper">
      <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12">
        <h2>{{ step5.title }}</h2>
        <h3>{{ step5.subtitle }}</h3>
        <div>
          <CardModel :disableClick="true" v-if="$store.getters.getSelectedVehicle" :data="$store.getters.getSelectedVehicle"/>
          <div class="form-input">
            <p>Datum in ura prevzema vozila</p>
            <p v-if="reservedTime" class="title-large">{{ reservedTime }}</p>
          </div>

          <div class="form-input step3__box">
            <LocationContact :representerDetails="representerDetails"></LocationContact>
          </div>

          <div class="flex order-md-1">
            <a role="button" class="button button--primary" target="_blank" :href="'https://www.google.com/maps/search/?api=1&query=' + directions">Prikaži pot do trgovca</a>
            <a role="button" class="button button--secondary" :href="urlTerm" target="_blank">Dodaj v koledar</a>
          </div>
        </div>
      </div>
      <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12" v-html="content">
      </div>
    </div>
  </section>
  `,
  setup () {
    const step5 = JSON.parse(document.querySelector('#app').dataset.step5)
    const store = Vuex.useStore()
    const representerDetails = Vue.ref(store.getters.getStep3.representer_list[0])
    const directions = Vue.ref(store.getters.getStep3.representer_list[0].latitude + ',' + store.getters.getStep3.representer_list[0].longitude)
    const reservedTime = Vue.ref(store.getters.getReservedTime)
    const content = JSON.parse(document.querySelector('#app').dataset.content)
    const urlTerm = window.API_URL + 'term-ical?termId=' + store.getters.getSelectedTermId

    return {
      step5,
      representerDetails,
      directions,
      content,
      reservedTime,
      urlTerm,
    }
  },
}

export default Step5
