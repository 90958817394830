/* global Vue, Vuex,  */
import Card from '../components/card'
import TextModule from '../components/textModule'
import Filters from '../components/filters'

const Step1 = {
  name: 'step1',
  components: {
    TextModule,
    Card,
    Filters,
  },
  template: `
  <section class="step1">
    <TextModule :step1="step1" />
    <div class="container">
      <Filters v-if="$store.getters.getStep1" title="Izberite model:" :options="$store.getters.getStep1.fuel_types" @updateDropdown="dropdownUpdated" :switchOn="false" :hideDropDown="false" :placeholder="'Vrsta goriva'" />
    </div>

    <div class="grid container" :style="$store.getters.getLoading ? 'min-height: 50vh' : ''">

      <div v-if="$store.getters.getStep1" class="col-6 col-sm-4 col-lg-3" v-for="car in filteredModels">
        <Card :data="car"/>
      </div>
    </div>
  </section>
  `,
  props: {
    searchParams: String,
  },
  setup (props) {
    const step1 = JSON.parse(document.querySelector('#app').dataset.step1)
    const dropdownSelected = Vue.ref('')
    const store = Vuex.useStore()

    const filteredModels = Vue.computed(() => {
      return store.getters.getStep1.models.filter((model) => {
        return !dropdownSelected.value || model.fuel_types.includes(dropdownSelected.value)
      }).sort((a, b) => {
        return a.brand_model_id - b.brand_model_id
      })
    })

    function fetchData () {
      store.commit('setLoading')
      fetch(`${window.API_URL}model-list?domain=testiraj.skoda.si&${props.searchParams}`,
        {
          method: 'get',
        })
        .then(response => response.json())
        .then((data) => {
          data.records.fuel_types.unshift('Vsa goriva')
          store.commit('setStep1', data.records)
          store.commit('setLoaded')

          // go to second step, if only one model
          if (data.records.models.length === 1) {
            store.commit('setSelectedModel', data.records.models[0])
            store.commit('goNext')
          }
        })
    }

    Vue.onBeforeMount(() => {
      if (!store.getters.getStep1) fetchData()
    })

    function dropdownUpdated (value) {
      if (value === 'Vsa goriva') {
        dropdownSelected.value = ''
      } else dropdownSelected.value = value

      store.commit('setFuelType', dropdownSelected.value)
    }

    return {
      step1,
      filteredModels,
      dropdownSelected,
      dropdownUpdated,
    }
  },
}

export default Step1
