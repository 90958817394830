/* global Vuex */

const Card = {
  name: 'Card',
  template: `
  <article v-if="data" class="card" @click="handleClick">
  <div>
    <h5 class="model-title">{{ data.name }}</h5>
    <span class="icon-plus"></span>
  </div>
  <img :src="'/media/uploads/' + data.brand_model_id + '.png'" :alt="data.name">
</article>
  `,
  props: ['data'],
  setup (props, { emit }) {
    const store = Vuex.useStore()

    const handleClick = () => {
      store.commit('setSelectedModel', props.data)
      store.commit('goNext')
    }

    return { handleClick }
  },
}

export default Card
