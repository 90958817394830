const LocationContact = {
  name: 'LocationContact',
  template: `
    <p>Lokacija prevzema</p>
    <div class="location-contact">
      <h5 class="model-name">{{ representerDetails.name}}</h5>
      <p class="model-desc">{{ representerDetails.address }}</p>
      <p class="model-desc">{{ representerDetails.post_number }} {{ representerDetails.post_name }}</p>
      <hr />
      <p class="model-desc">
        <a target='_blank' :href="'tel:' + representerDetails.phone.replace(' ', '')">Tel. prodaja: {{ representerDetails.phone }}</a>
      </p>
      <p class="model-desc">
        <a target='_blank' :href="'mailto:' + representerDetails.email ">Email: {{ representerDetails.email }}</a>
      </p>
    </div>
  `,
  props: ['representerDetails'],
}

export default LocationContact
