/* global Vue, Vuex, dropdown */

import CardModel from '../components/card-model'
import TermsList from './json/term-list.json'

const Step4 = {
  name: 'step4',
  components: {
    CardModel,
    dropdown,
  },
  template: `
  <section class="step4" v-if="$store.getters.getSelectedModel">
    <div class="grid container card-wrapper">
      <div class="col-xl-8 offset-xl-2 col-md-10 offset-md-1 col-12">
        <h2>{{ step4.title}}<span>2/2</span></h2>
        <div>
          <CardModel :disableClick="true" v-if="$store.getters.getSelectedVehicle" :data="$store.getters.getSelectedVehicle"/>
          <label class="form-input">
            {{ step4.firstname.label }}
            <input type="text" v-model="firstName" @blur="handleBlur" :placeholder="step4.firstname.placeholder">
          </label>

          <label class="form-input">
          {{ step4.lastname.label }}
            <input type="text" v-model="lastName" @blur="handleBlur" :placeholder="step4.lastname.placeholder">
          </label>

          <label class="form-input">
          {{ step4.email.label }}
            <input type="email" v-model="email" @blur="handleBlurEmail" :placeholder="step4.email.placeholder">
          </label>

          <div class="form-input complex">
            <p>{{ step4.phone.label }}</p>
            <dropdown v-model="dialCode" @blur="handleBlurPhone" :options="prefixes" />
            <input type="text" class="phone-number" @blur="handleBlurPhone" v-model="phone">
            <p class="model-desc text-danger" v-show="phoneUsed">Za vpisano telefonsko število že obstaja rezervacija testne vožnje. Za pomoč prosim kontaktirajte naš klicni center na 080 88 46.</p>
            <p class="model-desc text-danger" v-show="termReserved">Izbran termin žal ni več na voljo. Za pomoč prosim kontaktirajte naš klicni center na 080 88 46.</p>
            <p class="model-desc text-danger" v-show="apiError">Napaka pri rezervaciji. Prosimo poskusite ponovno kasneje, ali za pomoč kontaktirajte naš klicni center na 080 88 46.</p>
          </div>

          <p class="description">
            *Vpišite vašo telefonsko številko za potrditev rezervacije. Če številka ni pravilna, ne moremo zagotoviti
            potrditve rezervacije.
          </p>

          <div class="checkbox">
            <input type="checkbox" name="agree" id="agree" v-model="agree">
            <label for="agree">Strinjam se <a target="_blank" href="/media/uploads/splosni-pogoji-skoda-testne-voznje.pdf">s splošnimi pogoji storitve</a> testna vožnja.</label>
          </div>

          <div class="flex order--1">
            <a role="button" @click="$store.commit('goBack')" class="button">{{ step4.backbutton }}</a>
            <a role="button" @click="handleClick" :disabled="!isValid" class="button button--primary">{{ step4.nextbutton }}</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  `,
  setup () {
    const step4 = JSON.parse(document.querySelector('#app').dataset.step4)
    const firstName = Vue.ref(null)
    const lastName = Vue.ref(null)
    const email = Vue.ref(null)
    const agree = Vue.ref(null)
    const dialCode = Vue.ref(null)
    const phone = Vue.ref(null)
    const prefixes = Vue.ref(TermsList.records.mobile_phone_prefix_list.sort((a, b) => a - b))
    const phoneUsed = Vue.ref(false)
    const termReserved = Vue.ref(false)
    const apiError = Vue.ref(false)
    const store = Vuex.useStore()

    function validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    }

    function validateNumbers (email) {
      const reg = /^-?\d*\.?\d*$/
      return reg.test(String(email).toLowerCase())
    }

    function handleBlur (e) {
      if (!e.target.value.length > 0) e.target.parentNode.classList.add('has-error')
      else e.target.parentNode.classList.remove('has-error')
    }

    function handleBlurPhone (e) {
      if (!e.target.value.length > 0 || !validateNumbers(e.target.value)) e.target.parentNode.classList.add('has-error')
      else e.target.parentNode.classList.remove('has-error')
    }

    function handleBlurEmail (e) {
      if (!e.target.value.length > 0 || !validateEmail(e.target.value)) e.target.parentNode.classList.add('has-error')
      else e.target.parentNode.classList.remove('has-error')
    }

    const isValid = Vue.computed(() => {
      return firstName.value && lastName.value && validateEmail(email.value) && dialCode.value && phone.value && phone.value.length > 5 && validateNumbers(phone.value) && agree.value
    })

    function handleClick () {
      if (!isValid.value) return
      store.commit('setUserInfo', {
        firstName,
        lastName,
        email,
        phone: dialCode.value + phone.value,
      })

      reserveTerm()
    }

    function reserveTerm () {
      store.commit('setLoading')
      termReserved.value = false
      phoneUsed.value = false
      apiError.value = false
      fetch(`${window.API_URL}term-reserve`, {
        method: 'post',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
          {
            term_id: store.getters.getSelectedTermId,
            representer_id: store.getters.getStep3.representer_list[0].id,
            name: firstName.value,
            lastname: lastName.value,
            email: email.value,
            phone: dialCode.value + phone.value,
          }),
      }).then(res => res.json())
        .then((res) => {
          if (res.success) {
            store.commit('setReservedTime', res.records.reserved_time)
            setTimeout(() => {
              store.commit('goNext')
            }, 30)

            setTimeout(() => {
              store.commit('setLoaded')
            }, 250)
          } else {
            switch (res.message) {
              case 'reserved':
                termReserved.value = true
                break
              case 'phone number is in use':
                phoneUsed.value = true
                break
              default:
                apiError.value = true
            }
            store.commit('setLoaded')
          }
        })

      // send ga event
      if (window.ga) {
        window.ga('send', 'event', 'prijava na testno vožnjo', 'klik', 'testne vožnje jesen 2021')
      }
    }

    return {
      step4,
      firstName,
      agree,
      dialCode,
      lastName,
      email,
      phone,
      phoneUsed,
      termReserved,
      apiError,
      prefixes,
      isValid,
      handleClick,
      handleBlur,
      handleBlurPhone,
      handleBlurEmail,
    }
  },
}

export default Step4
