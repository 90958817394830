/* global Vuex */

export default new Vuex.Store({
  state: {
    loading: false,
    prevIndex: 0,
    currentIndex: 0,
    selectedModel: {},
    fuel_type: null,
    step1: null,
    step2: {},
    step3: null,
    modelType: null,
    selectedProvider: null,
    selectedTransimission: null,
    selectedVehicle: null,
    selectedDate: null,
    reservedTime: null,
    user: {},
    selectedTermId: null,
  },
  mutations: {
    goNext (state) {
      state.prevIndex = state.currentIndex
      ++state.currentIndex
      document.body.scrollIntoView({ behavior: 'instant', block: 'start' })
    },
    goBack (state) {
      document.body.scrollIntoView({ behavior: 'instant', block: 'start' })
      state.prevIndex = state.currentIndex
      --state.currentIndex
    },
    setStep1 (state, payload) {
      const obj = Object.assign({}, payload)
      state.step1 = obj
    },
    setStep2 (state, payload) {
      const obj = Object.assign({}, payload)
      state.step2 = obj
    },
    setStep3 (state, payload) {
      const obj = Object.assign({}, payload)
      state.step3 = obj
    },
    setSelectedModel (state, payload) {
      const obj = Object.assign({}, payload)
      state.selectedModel = obj
    },
    setModelType (state, payload) {
      state.modelType = payload
    },
    setFuelType (state, payload) {
      state.fuel_type = payload
    },
    setSelectedProvider (state, payload) {
      state.selectedProvider = payload
    },
    setSelectedVehicle (state, payload) {
      const obj = Object.assign({}, payload)
      state.selectedVehicle = obj
    },
    setselectedTransimission (state, payload) {
      state.selectedTransimission = payload
    },
    setSelectedDate (state, payload) {
      state.selectedDate = payload
    },
    setSelectedTermId (state, payload) {
      state.selectedTermId = payload
    },
    setUserInfo (state, payload) {
      const obj = Object.assign({}, payload)
      state.user = obj
    },
    setReservedTime (state, payload) {
      state.reservedTime = payload
    },
    setLoading (state) {
      state.loading = true
    },
    setLoaded (state) {
      state.loading = false
    },
  },
  getters: {
    getCurrentIndex: (state) => state.currentIndex,
    getSelectedModel: (state) => state.selectedModel,
    getPrevIndex: (state) => state.prevIndex,
    getFuelType: (state) => state.fuel_type,
    getStep1: (state) => state.step1,
    getStep2: (state) => state.step2,
    getStep3: (state) => state.step3,
    getModelType: (state) => state.modelType,
    getSelectedProvider: (state) => state.selectedProvider,
    getLoading: (state) => state.loading,
    getSelectedVehicle: (state) => state.selectedVehicle,
    getTransmissionList: (state) => state.step2.transmission_types,
    getSelectedDate: (state) => state.selectedDate,
    getSelectedTermId: (state) => state.selectedTermId,
    getReservedTime: (state) => state.reservedTime,
    getUser: (state) => state.user,
    getSelectedTransmision: (state) => state.selectedTransimission,
  },
})
