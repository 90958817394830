/* global Vue,Vuex, dropdown */
import CardModel from '../components/card-model'
import Filters from '../components/filters'

const Step2 = {
  name: 'step2',
  components: {
    CardModel,
    Filters,
    dropdown,
  },
  template: `
  <section class="step2" v-if="$store.getters.getSelectedModel && !$store.getters.getLoading">
    <div class="container">
        <h2>{{ step2.title }} </h2>
        <p>{{ step2.description }}</p>
        <dropdown v-model="modelProvider" optionLabel="name" :options="modelProviderList" @change="dropdownUpdatedProvider" placeholder="Vsi trgovci" />
    </div>
    <div class="container">
      <Filters ref="transmissionSwitch" :switchOptions="modelSwitchOptions" title="Izberite izvedbo modela:" :switchOn="true" @updateSwitch="selectTransmission" :hideDropDown="true" />
    </div>

    <div class="grid container card-wrapper" :class="{'allLoaded' : loadMore }" v-if="$store.getters.getSelectedModel">
      <div class="col-lg-3 col-sm-4 col-xs-6 col-12" v-if="!$store.getters.getLoading" v-for="car in filteredVehicles">
        <CardModel v-if="$store.getters.getSelectedModel" :disableClick="false" :data="car" :transmissions="modelSwitchOptions" />
      </div>

      <div class="col-12 step2__button" v-if="!loadMore && filteredVehicles.length">
        <a role="button" class="button" @click="loadMore = true">Več</a>
      </div>

      <div class="col-12 step2__no-vehicles text-xl" v-if="!filteredVehicles.length && !$store.getters.getLoading">
        Trenutno ni na voljo nobenega modela, ki bi ustrezal vašim nastavitvam. Prosim spremenite nastavitve.
      </div>
    </div>
  </section>
  `,
  props: {
    searchParams: String,
  },
  emits: ['forceSwitchTrue'],
  setup (props, { emit }) {
    const step2 = JSON.parse(document.querySelector('#app').dataset.step2)
    const modelProvider = Vue.ref(null)
    const modelSwitchOptions = Vue.ref([])
    const modelProviderList = Vue.ref(null)
    const modelSwitch = Vue.ref(null)
    const loadMore = Vue.ref(true)
    const store = Vuex.useStore()
    const transmissionSwitch = Vue.ref(null)
    let vehicles = 0

    const filteredVehicles = Vue.computed(() => {
      if (store.getters.getStep2 === null) return []
      if ((modelSwitch.value === 'all' && modelProvider.value === null) || (modelSwitch.value === 'all' && modelProvider.value.id === -1)) return store.getters.getStep2.vehicle_list
      if (modelSwitch.value === 'all' && modelProvider.value !== null) return store.getters.getStep2.vehicle_list.filter((model) => model.owner_id === modelProvider.value.id)

      vehicles = store.getters.getStep2.vehicle_list

      const models = modelProvider.value && modelProvider.value.id !== -1 && modelSwitch.value
        ? vehicles.filter((model) => model.owner_id === modelProvider.value.id && modelSwitch.value === model.shift)
        : vehicles.filter((model) => modelSwitch.value === model.shift)

      if (models.length > 4) loadMore.value = false
      if (models.length === 0) document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return models
    })

    function dropdownUpdatedProvider (value) {
      if (value.value.id !== store.getters.getSelectedProvider) {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        store.commit('setSelectedProvider', value.value.id)
      }
    }

    function selectTransmission (value) {
      const index = value === '1' ? 1 : 0
      let tranmission = modelSwitchOptions.value[index].key
      modelSwitch.value = tranmission
      if (value === 'all') {
        modelSwitch.value = value
        tranmission = value
      }

      if (tranmission !== store.getters.getSelectedTransimission) {
        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        store.commit('setselectedTransimission', tranmission)
      }
    }

    function setValuesToModels (res) {
      const availableProviders = res.vehicle_list.map(item => item.owner_id).filter((value, index, self) => self.indexOf(value) === index)
      modelSwitchOptions.value = res.transmission_types
      modelProviderList.value = res.partner_list.filter(element => availableProviders.includes(element.id))
      modelProviderList.value.unshift({ name: 'Vsi trgovci', id: -1 })
      modelSwitch.value = 'all'
    }

    function fetchData () {
      store.commit('setLoading')
      fetch(`${window.API_URL}vehicle-list?${props.searchParams}`, {
        method: 'post',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ modelId: store.getters.getSelectedModel.id, fuel_type: store.getters.getFuelType }),
      }).then(res => res.json())
        .then((res) => {
          store.commit('setLoaded')
          store.commit('setStep2', res.records)
          setValuesToModels(res.records)
        })
    }

    Vue.onBeforeMount(() => {
      if (!store.getters.getStep2 || store.getters.getPrevIndex < 1) fetchData()
      else setValuesToModels(store.getters.getStep2)
    })

    return {
      step2,
      filteredVehicles,
      modelProvider,
      modelProviderList,
      modelSwitch,
      modelSwitchOptions,
      transmissionSwitch,
      loadMore,
      dropdownUpdatedProvider,
      selectTransmission,
    }
  },
}

export default Step2
