function externalLinks () {
  Array.from(document.links).forEach((el) => {
    const link = el
    if (link.hostname !== window.location.hostname) {
      link.target = '_blank'
      link.res = 'noopener'
    }
  })
}

externalLinks()

;
import lozad from 'lozad'

function lazyLoad () {
  const els = document.querySelectorAll('.lazyload')
  const observer = lozad(els, {
    rootMargin: '10px 0px',
    threshold: 0,
    enableAutoReload: true,
  })
  observer.observe()
}

lazyLoad()

;
/* global Vue, primevue, ElementPlus */
import VueHeader from './components/header'
import Step1 from './steps/step1'
import Step2 from './steps/step2'
import Step3 from './steps/step3'
import Step4 from './steps/step4'
import Step5 from './steps/step5'
import store from './store'
import * as params from '@params'

const App = {
  components: {
    'v-header': VueHeader,
    'v-step1': Step1,
    'v-step2': Step2,
    'v-step3': Step3,
    'v-step4': Step4,
    'v-step5': Step5,
  },
  template: `
    <div key="loader" class="lds-roller" v-if="$store.getters.getLoading">
      <section>
        <img src="/media/brand/loader.gif" alt="loader" />
      </section>
    </div>
    <v-header key="header"/>
    <v-step1 v-show="$store.getters.getCurrentIndex===0" :searchParams="urlParams" key="1"/>
    <v-step2 v-if="$store.getters.getCurrentIndex===1" :searchParams="urlParams" key="2"/>
    <v-step3 v-show="$store.getters.getCurrentIndex===2" key="3"/>
    <v-step4 v-show="$store.getters.getCurrentIndex===3" key="4"/>
    <v-step5 v-if="$store.getters.getCurrentIndex===4" key="5"/>
    `,
  setup () {
    let urlParams = window.location.search.replace('?', '')
      .split('&')
      .filter(function (item) { return item.split('=').length === 2 })

    const hashValue = Number(location.hash.replace('#', ''))
    if (!urlParams.model_id && hashValue && !isNaN(hashValue)) {
      urlParams.push(`model_id=${hashValue}`)
    }

    urlParams = urlParams.join('&')
    return { urlParams }
  },
}
ElementPlus.locale(ElementPlus.lang.sl)
const app = Vue.createApp(App)
app.use(store)
app.use(primevue, {
  locale: {
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dateFormat: 'dd.mm.yy',
  },
})
app.use(ElementPlus)
app.mount('#app')
